ul.list-autocomplete {
  list-style: none;
  width: 97%;
  padding: 0;
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 4px;
  right: 0;
}

.list-autocomplete li {
  padding: 0;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  background-color: #ffffff;
  font-size: 1rem;
}

.list-autocomplete li:last-child {
  border-bottom: 0;
}

.list-autocomplete li:hover {
  background: #e8e8e8;
}
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}
/*when hovering an item:*/
.autocomplete-items li:hover {
  background-color: #e9e9e9;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}

/* segundo autocomplate que usa datalist */
/*
.wrapper {
  width: 100%;
  height: 100%;
  background: #efefef;
  box-shadow: 1px 1px 10px #999;
  margin: auto;
  text-align: center;
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding-top: 5px;
}*/

.scroll {
  overflow-x: scroll;
  overflow-y: scroll;
  height: 550px;
  white-space: nowrap;
}

.list-autocomplete li:focus {
  background-color: red;
}

.input {
  padding: 5px 10px;
  font-size: 16px;
  color: #555;
  border: 1px solid #b1b1b1;
  border-radius: 5px;
  outline: none;
  position: relative;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}

.input:focus {
  border: 1px solid #777;
}

.list {
  z-index: 99;
  position: absolute;
  width: 100%;
  top: 74px;
  max-height: 250px;
  overflow-x: scroll;
  background: rgba(33, 33, 33, 0.7);
  color: #fff;
  border-radius: 5px;
  overflow-x: hidden;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.list li {
  padding: 6px 10px;
  cursor: pointer;
  font-size: 15px;
}

.list li:hover {
  background: rgba(33, 33, 33, 0.3);
}

.arrow-up {
  z-index: 99;
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(33, 33, 33, 0.7);
  position: absolute;
  top: 64px;
  left: 20px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.show {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.hide {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  -webkit-transition: none;
  transition: none;
}

.selecting {
  background: rgba(33, 33, 33, 0.3);
}
